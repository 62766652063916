import React, { useEffect, useState, useRef } from 'react';
import { Box, TextField, Button, Typography, IconButton } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import axios from 'axios';
import { TranscribeStreamingClient, StartStreamTranscriptionCommand, AudioStream } from "@aws-sdk/client-transcribe-streaming";
import logo from "./velappity_logo_white.png";

type Message = {
  text: string;
  human: boolean;
};

type Chat = {
  name: string;
  messages: Message[];
};

interface ChatPanelProps {
  chat: Chat;
  onSendMessage: (message: Message) => void;
  chats: Chat[];
  updateMessage: (text: string, chatName: string, firstCall: boolean) => void;
}

const ChatPanel: React.FC<ChatPanelProps> = ({ chat, onSendMessage, chats, updateMessage }) => {

    const [isRecording, setIsRecording] = useState(false);
    const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
    const [input, setInput] = useState('');
    const [output, setOutput] = useState('');

    // AWS Client Initialization
    const client = new TranscribeStreamingClient({
      region: "us-east-1",
      // Move these to secure storage
      credentials: {
          accessKeyId: 'AKIAWKQ4LRGLUOPLAJ3T',
          secretAccessKey: '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',
      },
  });

    /*const [isRecording, setIsRecording] = useState(false); // to track recording status
    const [audioChunks, setAudioChunks] = useState<Blob[]>([]); // to store audio chunks

    let mediaRecorder: MediaRecorder | null = null;

    const handleMicrophoneClick = async () => {
        console.log("isRecording: " + isRecording)
        if (!isRecording) {
            console.log("RECORDING STARTED");

            mediaRecorder = new MediaRecorder(await window.navigator.mediaDevices.getUserMedia({ audio: true }));
            mediaRecorder.start();
            console.log(mediaRecorder);
            setIsRecording(true);
    
            const client = new TranscribeStreamingClient({
                region: "us-east-1",
                credentials: {
                    accessKeyId: 'AKIAWKQ4LRGLUOPLAJ3T',
                    secretAccessKey: '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',
                },
            });
        
            mediaRecorder.ondataavailable = async event => {
                console.log("data available");
                audioChunks.push(event.data);
                const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
                const reader = new FileReader();
                
                reader.onload = async (ev) => {
                    console.log("sending audio to AWS");
                    const audioBuffer = Buffer.from(ev.target?.result as ArrayBuffer);
                    const audioBufferUint8 = new Uint8Array(audioBuffer);
        
                    const command = new StartStreamTranscriptionCommand({
                        LanguageCode: 'en-US',
                        MediaEncoding: 'pcm',
                        MediaSampleRateHertz: 44100, // Adjust based on source
                        AudioStream: {
                            [Symbol.asyncIterator]: async function*() {
                                yield { AudioEvent: { AudioChunk: audioBufferUint8 } };
                            }
                        }
                    });
    
                    const response = await client.send(command);
                    console.log(response);
                    if (response.TranscriptResultStream) {

                        for await (const event of response.TranscriptResultStream) {
                            if (event.TranscriptEvent && event.TranscriptEvent.Transcript?.Results) {
                                const transcript = event.TranscriptEvent.Transcript.Results
                                    .map(result => result.Alternatives ? result.Alternatives[0].Transcript : "")
                                    .join('\n');
                                console.log(transcript);
                                setInput(transcript);
                            }
                        }
                    }
                };
    
                reader.readAsArrayBuffer(audioBlob);
            };
    
            mediaRecorder.onstop = async () => {
                // Handle transcription completion or any other necessary clean-up
                console.log("Media Recorder Stopped")
            };
        } else {
            console.log("RECORDING STOPPED")
            console.log(mediaRecorder)
            if (mediaRecorder) {
                mediaRecorder.stop();
            }
            setIsRecording(false);
        }
    };*/

  const messagesEndRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (messagesEndRef.current) {
          messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
      }
  }, [chat.messages]);

  const handleMicrophoneClick = async () => {
      if (!isRecording) {
          
          const stream = await window.navigator.mediaDevices.getUserMedia({ audio: true });
          const newRecorder = new MediaRecorder(stream);
          newRecorder.ondataavailable = handleDataAvailable;
          newRecorder.onstop = handleStopRecording;
          newRecorder.start();
          console.log(newRecorder.state)

          setMediaRecorder(newRecorder);
          console.log(newRecorder.state)
          setIsRecording(true);
      } else {
          if (mediaRecorder) {
              mediaRecorder.stop();
              console.log(mediaRecorder?.state)
              setIsRecording(false);
          }
      }
  };

  const handleDataAvailable = (event: BlobEvent) => {
    console.log("DATA AVAILABLE: ", event.data.size)
    console.log(event.data)
      setAudioChunks(prevChunks => [...prevChunks, event.data]);

      // Create a Blob object from the audio chunks
    const audioBlob = new Blob([event.data], { type: 'audio/wav' });
    
    // Create an Object URL for the Blob
    const audioURL = window.URL.createObjectURL(audioBlob);

    // Create a link to download the audio file
    const downloadLink = document.createElement('a');
    downloadLink.href = audioURL;
    downloadLink.download = 'recorded_audio.wav';  // Filename for the download
    downloadLink.style.display = 'none';

    document.body.appendChild(downloadLink);  // Append the link to the DOM
    downloadLink.click();  // Simulate a click to start the download

    // Cleanup: Remove the link and revoke the Object URL
    document.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(audioURL);
  };

  const handleStopRecording = async () => {
    console.log("STOPPED RECORDING")
      const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
      const reader = new FileReader();
      reader.onload = async (ev) => {
          const audioBuffer = new Uint8Array(ev.target?.result as ArrayBuffer);
          await sendToAWS(audioBuffer);
      };
      reader.readAsArrayBuffer(audioBlob);
      setAudioChunks([]); // Clear the audio chunks for the next recording
  };

  const REGION = 'us-east-1';  // e.g., 'us-east-1'
  const SAMPLE_RATE = 44100;  // Example value, adjust as needed
  const AUDIO_PATH = '../../../Downloads/recorded_audio.wav';
  const CHUNK_SIZE = 6400;  // Example value, adjust as needed
  const BYTES_PER_SAMPLE = 2;  // Example value, adjust as needed
  const CHANNEL_NUMS = 1;  // Example value, adjust as needed



  const sendToAWS = async (audioBuffer: Uint8Array) => {



    const command = new StartStreamTranscriptionCommand({
      LanguageCode: 'en-US',
      MediaSampleRateHertz: SAMPLE_RATE,
      MediaEncoding: 'pcm',
      AudioStream: {
        [Symbol.asyncIterator]: async function* () {
            yield { AudioEvent: { AudioChunk: audioBuffer } };
        }
        }
    });

    console.log("Sending audio to AWS")

    try {
        const response = await client.send(command);
        console.log(response)
        if (response.TranscriptResultStream) {
            console.log("TranscriptResultStream: ", response.TranscriptResultStream)
            for await (const event of response.TranscriptResultStream) {
                console.log(event)
                if (event.TranscriptEvent && event.TranscriptEvent.Transcript?.Results) {
                    console.log("TranscriptEvent")
                    const transcript = event.TranscriptEvent.Transcript.Results
                        .map(result => result.Alternatives ? result.Alternatives[0].Transcript : "")
                        .join('\n');
                    console.log(transcript);
                    setInput(transcript);
                }
            }
        }
    } catch (error) {
        console.error("Error sending audio to AWS:", error);
    }
};
  

    // NEW: Handle sending a message
    const handleSendMessage = async () => {
        if (!input) return;
        const messageInput = input;
        setInput("");
        console.log("sending message: " + messageInput)
        onSendMessage({ text: messageInput, human: true });
        console.log(chat)
        // Get GPT-4 response
        await callGPT4(messageInput);

        // Create a new message
        const newMessage: Message = { text: output, human: false };

        // Call the parent's onSendMessage function to update the messages
        onSendMessage(newMessage);

    };

    const handleEnterPressed = async (event: { key: string; }) => {
      if (!input) return;
      if (event.key === 'Enter') {
        const messageInput = input;
        setInput("");
        console.log("sending message: " + messageInput)
        onSendMessage({ text: messageInput, human: true });
        console.log(chat)
        // Get GPT-4 response
        await callGPT4(messageInput);

        // Create a new message
        const newMessage: Message = { text: output, human: false };

        // Call the parent's onSendMessage function to update the messages
        onSendMessage(newMessage);
      }

  };

    function getContentFromJSON(jsonString: string): string | null {
        try {
            const jsonStrings = jsonString.split("data:");
            jsonStrings.shift();
            let contents = [];
            for (const i of jsonStrings) {
                contents.push(JSON.parse(i).choices[0].delta.content);
            }
            const content = contents.join("");

            // Assuming you want the content from the first choice
            return content;
        } catch (error) {
            console.error("Error parsing JSON:", error);
            return null;
        }
    }
    
    const callGPT4 = async (message: string) => {
        // Define your query
        const queries = [
        { query: message },
        ];
    
         // CORS Anywhere proxy URL

        const corsProxy = 'https://corsproxy.io/?';

        // Orca endpoint
        //const orcaEndpoint = 'http://54.211.123.23:8080/query';

        const orcaEndpoint = 'https://orca-app-9x3k8.ondigitalocean.app/query';

        // Use axios to make a POST request to the Orca endpoint via the CORS proxy
        const orcaResponse = await axios.post(
            //corsProxy + orcaEndpoint, // Prepend the proxy URL here
            corsProxy + orcaEndpoint,
            { queries },
            {
            headers: {
                Authorization: `Bearer qwertyuiop`,
                host: "http://velappity-chatbot-webapp.s3-website-us-east-1.amazonaws.com/",
            },
            }
  );
    
        const handbook_relevant_info = orcaResponse.data.results[0].results.map((result: any) => result.text).join('. ');
        console.log(handbook_relevant_info)
            
        try {
            const gpt4Response = await fetch(
            'https://api.openai.com/v1/chat/completions',
            {
                method: "POST",
                body: JSON.stringify({
                    model: 'gpt-3.5-turbo',
                    messages: [
                    {
                        role: 'user',
                        content: `The previous conversation history is: ${JSON.stringify(chats)}. Using the following text, please advise on ${queries[0].query}: ${handbook_relevant_info}. Please note that if it doesn't seem to make sense, please try to act as much as possible as a conversational helpful chatbot that could easily be mistaken for a customer service assistant.`,
                    },
                    ],
                    stream: true,
                }),
                headers: {
                Authorization: `Bearer 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`,
                'Content-Type': 'application/json',
                },
            }
            );

            
            console.log(gpt4Response)

            const reader = gpt4Response.body ? gpt4Response.body.getReader() : ({} as ReadableStreamDefaultReader<Uint8Array>);
            const decoder = new TextDecoder('utf-8');

            let message = '';

            while (true) {
                const { done, value } = await reader.read();
                if (done) {
                  break;
                }
                
                const chunk = decoder.decode(value);

                const lines = chunk.split("\\n");

                const parsedLines = lines.map((line) => getContentFromJSON(line));
                for (const content of parsedLines) {
                  // Update the UI with the new content
                  console.log(content)
                  message = message + content;
                
                  console.log(message)
                }
                let firstCall = false;

                if (message === '') {
                  firstCall = true;
                }
                

                updateMessage(message, chat.name, firstCall);
                //onSendMessage({ text: output, human: false });
              }

        } catch (error) {
            console.log(error);
        }
    };

    const formatInlineList = (text: string) => {
      const items = text.split(/(\d+\.\s[^.]+\.)/g);
  return items.map((item, index) => {
    const isListItem = /^\d+\./.test(item);
    const nextIsNotListItem = items[index + 1] && !/^\d+\./.test(items[index + 1]);
    return (
      <React.Fragment key={index}>
        {isListItem ? (
          <div style={{ paddingLeft: '20px' }}>
            <br />
            {item}
            <br />
            {nextIsNotListItem ? <br /> : null}
          </div>
        ) : (
          item
        )}
      </React.Fragment>
    );
  });
    };

    return (
    <Box sx={{ padding: '0px', height: '100%', display: 'flex', flexDirection: 'column', background: 'white' }}>
      <Box sx={{ padding: '16px', background: '#2F4050', color: '#fff', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: "30px" }}>
        
        <img src={logo} width="161px" height="36px"/>
        <Typography variant="h6">{"Velappity Chat Bot"}</Typography>
        
      </Box>
      <Box sx={{ overflowY: 'scroll', flexGrow: 1, mb: 2, borderBottom: "1px solid lightgrey"  }} ref={messagesEndRef}>
        {chat.messages.map((message, index) => (
        <Box
        key={index}
        sx={{
          display: 'flex',
          justifyContent: message.human ? 'flex-end' : 'flex-start',
          padding: "20px"
        }}
      >
          <Box
            key={index}
            sx={{
              padding: '10px',
              maxWidth: '70%',
              borderRadius: '15px',
              background: message.human ? '#3578E5' : '#3f3f3f',
              color: '#fff',
              alignSelf: message.human ? 'flex-end' : 'flex-start',
              marginBottom: '8px',
            }}
          >
            <Typography variant="body1">{formatInlineList(message.text)}</Typography>
          </Box>
        </Box>
        ))}
      </Box>
        <Box sx={{ display: 'flex' }}>
           <TextField
           fullWidth
          id="outlined"
          label="Enter Message"
          value={input}
          onKeyDown={handleEnterPressed}
          onChange={e => setInput(e.target.value)}
          sx={{ marginLeft: 2, flexGrow: 1, marginBottom: '8px' }}
          defaultValue="Please start typing..."
        />
          <Button variant="contained" color="primary" onClick={handleSendMessage} sx={{ marginLeft: 4, width: '160px', marginBottom: '8px', marginRight: 2 }}>
            Send
          </Button>
        </Box>
      </Box>
    
    );
  };
  
  export default ChatPanel;
import React, { useState } from 'react';
import { Container, Box, Paper, Typography } from '@mui/material';
import ChatMenu from './ChatMenu';
import ChatPanel from './ChatPanel';
import globalStyles from './GlobalStyles';

type Message = {
  text: string;
  human: boolean;
};


type Chat = {
  name: string;
  messages: Message[];
};


const App: React.FC = () => {
  const [chats, setChats] = useState<Chat[]>([
    {
      name: 'Chat 1',
      messages: [{text: "Hello, I am a virtual assistant built to aid you with any queries related to Velappity. How may I help you?", human: false}]
    }
  ]);
  const [selectedChat, setSelectedChat] = useState<number | null>(0);

  /*const onSendMessage = (message: Message) => {
    setChats((prevChats) => {
      const newChats = [...prevChats];
      newChats[selectedChat!].messages.push(message);
      console.log(newChats);
      return newChats;
    });
    console.log(chats)
  };*/

  const onSendMessage = (message: Message) => {
    if (selectedChat !== null && chats[selectedChat]) {
      updateChatMessages(chats[selectedChat].name, message);
    }
  };

  const removeNullFromText = (text: string): string => {
    return text.replace(/null/g, '. ').trim();
};

  /*const updateMessage = (text: string, chatName: string, firstCall: boolean) => {
    setChats((prevChats) => {
      const newChats = [...prevChats];
      console.log(newChats);
      newChats.find((o, i) => {
        if (o.name === chatName) {
            if (firstCall) {
              newChats[i] = { name: chatName, messages: [...newChats[i].messages, {text: removeNullFromText(text.slice(0,-4)), human: false}] };
            } else {
              console.log(newChats[i].messages);
              newChats[i].messages.shift();
              newChats[i] = { name: chatName, messages: [...newChats[i].messages, {text: removeNullFromText(text.slice(0,-4)), human: false}] };
            }
            return true; // stop searching
        }
      });
      return newChats;
    });
  };*/

  

  const updateChatMessages = (chatName: string, newMessage: Message) => {
    setChats((prevChats) => {
      return prevChats.map((chat) => {
        if (chat.name !== chatName) return chat;
        if (chat.messages.length > 0 && chat.messages[chat.messages.length - 1].human === false && newMessage.text !== "" && newMessage.human === false) {
          chat.messages.pop();
        }
        if (newMessage.text.trim().length === 0) {
          return chat;
        }
        return {
          ...chat,
          messages: [...chat.messages, newMessage],
        };
      });
    });
  };

  /*const updateMessage = (text: string, chatName: string, firstCall: boolean) => {
    setChats((prevChats) => {
      return prevChats.map((chat, i) => {
        if (chat.name !== chatName) return chat; // Not the chat we are looking for
        
        let newMessages = [...chat.messages]; // Create a new messages array
        if (!firstCall) {
          newMessages.shift(); // Remove the first element if not the first call
        }
        newMessages.push({ text: removeNullFromText(text.slice(0, -4)), human: false });
        
        return { ...chat, messages: newMessages }; // Return a new chat object
      });
    });
  };*/

  const updateMessage = (text: string, chatName: string, firstCall: boolean) => {
    if (!firstCall) {
      // Here you can handle the removal of the first message if you still need that logic
    }
    
    const newMessage = { text: removeNullFromText(text.slice(0, -4)), human: false };
    updateChatMessages(chatName, newMessage);
  };

  const onSelectChat = (index: number | null) => {
    setSelectedChat(index);
  };

  const handleAddChat = () => {
    const newChat = {
      name: `Chat ${chats.length + 1}`,
      messages: []
    };
    setChats([...chats, newChat]);
  };
  /*
  return (<Container disableGutters maxWidth={false} sx={{ height: '100vh', display: 'flex' }}>
        {<Container disableGutters maxWidth={false} sx={{ height: '100vh', display: 'flex' }}>
      <Paper elevation={3} sx={{ width: '300px', height: '100%' }}>
        <ChatMenu chats={chats} onSelectChat={onSelectChat} onAddChat={handleAddChat} />
      </Paper>
  */

  return (
    <>
      {globalStyles}
      <Container disableGutters maxWidth={false} sx={{ height: '100vh', display: 'flex' }}>
        <Box sx={{ flexGrow: 1, overflowY: 'hidden', display: 'flex', flexDirection: 'column' }}>
          {chats[0] ? (
            <ChatPanel chat={chats[0]} onSendMessage={onSendMessage} chats={chats} updateMessage={updateMessage}/>
          ) : (
            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h4" textAlign="center" color="grey">
              Please select a chat
            </Typography>
          </Box>
          )}
        </Box>
      </Container>
    </>
    
  );
};

export default App;
import { GlobalStyles } from '@mui/material';

const globalStyles = (
  <GlobalStyles
    styles={{
      body: {
        margin: 0,
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#fafafa',
      },
      '#root': {
        height: '100%',
      },
      '*::-webkit-scrollbar': {
        width: '8px',
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(63, 81, 181, 0.6)',
        borderRadius: '4px',
      },
      '*::-webkit-scrollbar-thumb:hover': {
        backgroundColor: 'rgba(63, 81, 181, 0.8)',
      },
      '*::-webkit-scrollbar-track': {
        backgroundColor: 'rgba(63, 81, 181, 0.1)',
      },
    }}
  />
);

export default globalStyles;